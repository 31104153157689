.firm-service-hours-table-container,
.firm-service-fees-table-container,
.time-professional-table-container,
.time-category-dashboard-table-container,
.timesheet-metric-table {
  /* max-height: 350px; */
}
/* .timesheet-metric-table td{
  border : 1px solid #ddd;
} */
.firms-percentage-column {
  min-width: 149px;
  /* min-width: 125px; */
}
.timesheet-metric-table .sticky-column {
  position: sticky;
  top: 0px;
  z-index: 999;
  background: #fff;
}
.timesheet-metric-table .sticky-column-2 {
  position: sticky;
  top: 36px;
  z-index: 999;
  background: #fff;
}
.timesheet-metric-table .sticky-column-body {
  position: sticky;
  top: 0px;
  z-index: 99;
  background: #fff;
}
.timesheet-metric-table .sticky-column-header {
  background-color: #cccccc !important;
}
.timesheet-metric-table .firm-column {
  min-width: 161px;
  left: 0px;
}
.timesheet-metric-table .time-entries-column {
  min-width: 240px;
  left: 161px;
}
.timesheet-metric-table .month-entries-column {
  min-width: 250px;
  left: 425px;
  /* left: 405px; */
}
/* .timesheet-metric-table .firm-column {
  width: 160px;
  min-width: 160px;
  left: -1px;
} */
.timesheet-metric-table .time-all-data-column {
  width: 115px;
  min-width: 115px;
  left: 161px;
}
.timesheet-metric-table .time-percentage-data-column {
  width: 149px;
  min-width: 149px;
  /* width: 130px; */
  /* min-width: 130px; */
  left: 276px;
}
.timesheet-metric-table .months-all-data-column {
  width: 115px;
  min-width: 115px;
  left: 425px;
  /* left: 405px; */
}
.timesheet-metric-table .months-percentage-data-column {
  width: 149px;
  min-width: 149px;
  /* width: 150px; */
  /* min-width: 150px; */
  left: 540px;
  /* left: 520px; */
}
.dashboard-timesheet-main-container {
  /* width: calc(100% - 380px) !important; */
  max-height: calc(100vh - 135px) !important;
  overflow: auto;
  padding: 16px 16px 16px 8px;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
