.job-history-action {
  min-width: 60;
}
.job-history-professional {
  flex: 1;
}
.resource-details-container {
  padding: 16px !important;
  border: 1px solid #ddd;
  margin: 4px 8px !important;
}
.date-field {
  min-width: 170px;
  max-width: 170px;
}
@media screen and (max-width: 1440px) {
  .date-field {
    min-width: 160px;
    max-width: 160px;
  }
}
@media screen and (max-width: 1400px) {
  .date-field {
    min-width: 150px;
    max-width: 150px;
  }
}
.view-confirm-dialog {
  width: 350px;
}
.confirm-header {
  margin: 24px 0 !important;
}
