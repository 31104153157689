.load-filter-popover {
  padding: 8px 16px;
}
.load-filter-popover-container > div:nth-child(1) {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
.actions-menu {
  z-index: 9999 !important;
}
.tree-item-selected > div > div:nth-child(2) {
  background-color: rgba(63, 81, 181, 0.12) !important;
}
