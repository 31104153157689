.pie-chart-main-container {
  overflow-x: auto !important;
  overflow-y: hidden !important;
  padding: 8px !important;
}
.report-table-container {
  /* max-height: 500px; */
  /* overflow: auto; */
}
.pie-chart-container {
  padding-left: 8px;
  display: flex;
  flex-wrap: nowrap !important;
}
.pie-chart-card {
  width: 230px;
  min-width: 230px;
  height: 230px;
  padding: 8px;
  margin-right: 8px;
  margin-top: 16px;
}
.report-chart-title {
  margin: 0;
  text-align: center;
}
.report-table td {
  background: #fff;
  border: 1px solid #333;
}
.report-table,
.report-table td,
.report-table th {
  border: 1px solid #333;
}
.cross-tab-table-container {
  overflow: inherit !important;
}
.expand-collapse-icon {
  cursor: pointer;
  vertical-align: middle !important;
}
.cross-table-row-label {
  min-width: 230px;
}
.row-label-padding-1 {
  padding-left: 6px !important;
}
.row-label-padding-2 {
  padding-left: 30px !important;
}
.row-label-padding-3 {
  padding-left: 54px !important;
}
.row-label-padding-4 {
  padding-left: 78px !important;
}
.row-label-padding-5 {
  padding-left: 102px !important;
}
.grouped-cross-table-row td {
  font-weight: 700;
}
.cell-description {
  min-width: 280px;
}
.cell-resourceName,
.cell-timeCategoryName,
.cell-professionalLevelName,
.cell-firmName,
.cell-firmLongName {
  min-width: 150px;
  width: 150px;
  max-width: 150px;
}
.cell-time {
  width: 100px;
  min-width: 100px;
}
.cell-fees,
.cell-resources,
.cell-resourcesPercent,
.cell-hours,
.cell-hoursPercent,
.cell-fees,
.cell-feesPercent,
.cell-entriesPercent,
.cell-months,
.cell-monthsPercent,
.cell-rate,
.cell-amount,
.cell-serviceDate {
  width: 140px;
  min-width: 140px;
}
.cell-entries,
.cell-max-hourly-rates {
  width: 140px;
  min-width: 140px;
}
.chart-container,
.chart-container-0x,
.chart-container-1x {
  height: 550px;
  padding-top: 64px;
  width: 100%;
}
.cell-avg-hourly-rates,
.cell-min-hourly-rates {
  width: 130px;
  min-width: 130px;
}
.chart-container-2x {
  height: 650px;
}
.chart-container-3x {
  height: 750px;
}
.chart-container-4x {
  height: 850px;
}
.chart-container-5x {
  height: 950px;
}
.cross-table-row-label {
  position: sticky;
  left: -4px;
  z-index: 4 !important;
  min-width: 230px;
}
.crosstab-row-label-1,
.crosstab-row-label-2,
.crosstab-row-label-3,
.crosstab-row-label-4,
.crosstab-row-label-5 {
  position: sticky;
  left: -4px;
  z-index: 8 !important;
  min-width: 230px;
}
.crosstab-row-1 th,
.crosstab-row-label-1 {
  position: sticky;
  top: 0;
  z-index: 1;
  white-space: nowrap;
}
.crosstab-row-2 th,
.crosstab-row-label-2 {
  position: sticky;
  top: 36px;
  z-index: 1;
  white-space: nowrap;
}
.crosstab-row-3 th,
.crosstab-row-label-3 {
  position: sticky;
  top: 72px;
  z-index: 1;
  white-space: nowrap;
}
.crosstab-row-4 th,
.crosstab-row-label-4 {
  position: sticky;
  top: 108px;
  z-index: 1;
  white-space: nowrap;
}
.crosstab-row-5 th,
.crosstab-row-label-5 {
  position: sticky;
  top: 144px;
  z-index: 1;
  white-space: nowrap;
}
.cross-tab-table-container table{
    border-collapse: separate !important;
}
.committee-report-section{
  background: #f1f1f1;
}
.accordion-header{
  font-size: 16px !important;
  font-weight: 500 !important;
}