.rules-content{
    font-size: 16px;
}
.rules-content ol {
  counter-reset: item;
}
.rules-content li {
  display: block;
  color: #666666;
}
.rules-content li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}
