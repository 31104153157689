.db-not--exists-main-section,
.db-not--exists-main-section * {
  font-family: "Comfortaa", cursive;
}
.db-not--exists-main-section .banner-img {
  width: 100%;
  height: 430px;
  object-fit: cover;
}
.db-not--exists-main-container {
  max-height: calc(100vh - 64px);
}
.contact-us-header {
  padding: 16px 16px 0px 8px !important;
}
.contact-us-btn {
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: #c24933 !important;
}
.legal-fee-content {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
  margin-top: 0;
}
.legal-fee-container {
  padding: 24px 50px 24px 50px !important;
}
.reach-out-container {
  padding: 8px 50px 24px 50px !important;
  margin-bottom: 100px !important;
  margin-top: 32px !important;
  width: 100%;
}
.reach-out-container > div {
  background: #ffffff;
  border: 0.5px solid #c7ecf6;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 24px 50px 24px 50px;
}
.reach-out-header {
  font-weight: 700;
  font-size: 30px;
  line-height: 33px;
  margin-bottom: 16px !important;
}
.reach-out-email-container>div:nth-child(1) {
  flex: 1;
}
.reach-out-btn{
  background: #2D334F !important;
  min-width: 200px !important;
}
.contact-us-footer {
  background-color: #2d334f;
  padding: 24px 50px 24px 50px !important;
  color: #fff;
}
.footer-content {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 24px !important;
}
.contact-us-footer>div{
  margin-bottom: 32px !important;
}
.footer-copy-right-section{
  margin-top: 24px !important;
}
@media screen and (max-width:599px) {
  .legal-fee-container,.reach-out-container, .reach-out-container > div, .contact-us-footer{
    padding: 24px 32px 24px 32px !important
  }
  .reach-out-email-container{
    display: flex;
    flex-direction: column;
  }
}